<template>
  <div id="footer">
    <div id="copy_area">
      <div id="copy">
        <p id="footerlogo">損害保険ジャパン株式会社</p>
        <p id="copyright" v-if="mode == 'pc'">
          Copyright&copy;2014-2023 Sompo Japan Insurance Inc. All Rights
          Reserved.
        </p>
        <p id="copyright" v-if="mode == 'sp'">
          Copyright&copy;2014-2023<br />
          Sompo Japan Insurance Inc.<br />
          All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: String,
  },
};
</script>

<style scoped>
html {
  overflow-y: scroll;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
  word-wrap: break-word;
}

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  _position: relative;
}

sup {
  vertical-align: top;
  _vertical-align: baseline;
  _bottom: 0.2em;
}

sub {
  vertical-align: bottom;
  _vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, Arial, sans-serif;
}

header,
section,
footer {
  margin: 0 !important;
}

#footer {
  width: 100%;
  /*ie8 fix*/
  margin: 0 auto;
  height: 36px;
  background: #ffffff;
  position: relative;
}
#footer ul {
  width: 990px;
  *zoom: 1;
  margin: 10px auto;
  font-size: 12px;
  line-height: 100%;
  padding: 0 21px;
  box-sizing: border-box;
}
@media (max-width: 990px) {
  #footer ul {
    width: 100%;
  }
}
#footer ul:after {
  content: "";
  display: table;
  clear: both;
}
#footer ul li {
  float: left;
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
}
#footer ul li + li {
  border-left: 0;
  border-right: 1px solid #808080;
}
#footer ul li a {
  display: block;
  background: no-repeat 0 0 transparent;
  color: #808080;
  text-decoration: none;
}
#footer ul li a:hover {
  background-repeat: no-repeat;
  background-position: left top;
  color: #2b2b2b;
  text-decoration: underline;
}
#footer ul li a:hover img {
  visibility: hidden;
  _display: none;
}
#footer ul #fn_sitepolicy a {
  width: 123px;
}
#footer ul #fn_private a {
  width: 125px;
}
#footer ul #fn_solicitation a {
  width: 73px;
}
#footer ul #fn_guidance a {
  width: 125px;
}
#footer ul #fn_sitemap a {
  width: 93px;
}
#footer ul #fn_about_e a {
  width: 118px;
}
#footer ul #fn_privacy_e a {
  width: 105px;
}
#footer #copy_area {
  background: #cc0022;
  width: 100%;
  height: 36px;
}
#footer #copy_area #copy {
  width: 990px;
  position: relative;
  margin: 0 auto;
}
@media (max-width: 990px) {
  #footer #copy_area #copy {
    width: 100%;
  }
}
#footer #copy_area #copy #footerlogo {
  position: absolute;
  top: 10px;
  left: 21px;
  color: #ffffff;
  font-size: 72%;
}
#footer #copy_area #copy #copyright {
  position: absolute;
  top: 12px;
  right: 21px;
  color: #ffffff;
  font-size: 72%;
  text-align: right;
  font-family: "Helvetica", "Arial";
}
@media (max-width: 550px) {
  /* #footer #copy_area #copy #copyright {
    top: 26px;
    right: auto;
    left: 21px;
  } */
  #footer #copy_area #copy #footerlogo {
    top: 12px;
  }
  #footer #copy_area {
    height: 60px;
  }
  #footer ul #fn_guidance {
    border-left: 1px solid #808080;
    margin-top: 5px;
  }
  #footer ul #fn_sitemap {
    margin-top: 5px;
  }
}
</style>
