<template>
  <div id="header">
    <div id="headerwrapper">
      <h1 id="logo">
        <a
          href="https://www.sompo-japan.co.jp/"
          target="_blank"
          rel="noopener noreferrer"
          ><img
            src="./images/jpn_sj_comm-logomark_ja_type-b_basic_posi.jpg"
            alt="損保ジャパン"
        /></a>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: String,
  },
};
</script>

<style scoped>
html {
  overflow-y: scroll;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
  word-wrap: break-word;
}

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  _position: relative;
}

sup {
  vertical-align: top;
  _vertical-align: baseline;
  _bottom: 0.2em;
}

sub {
  vertical-align: bottom;
  _vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, Arial, sans-serif;
}

header,
section,
footer {
  margin: 0 !important;
}

#header {
  width: 100%;
  height: 82px;
  background-size: 1px 16px;
  position: relative;
}
#header:before {
  background-color: #cc0022;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
#header:after {
  background: url(./images/header_arc_bg_right.png) repeat-x center bottom;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
@media (min-width: 960px) and (max-width: 2400px) {
  #header:after {
    width: 33%;
  }
}
@media (min-width: 2401px) and (max-width: 4000px) {
  #header:after {
    width: 40%;
  }
}
#header #headerwrapper {
  width: 990px;
  height: 16px;
  background: url(./images/header_arc_bg_center.png) no-repeat center bottom;
  background-size: contain;
  margin: 0 auto;
  padding: 66px 0 0;
  position: relative;
  z-index: 2;
}
@media (max-width: 990px) {
  #header #headerwrapper {
    width: 100%;
  }
}
#header #headerwrapper #logo {
  position: absolute;
  top: 50%;
  left: 21px;
  transform: translateY(-50%);
}
#header #headerwrapper #logo a {
  display: block;
}
#header #headerwrapper #logo a img {
  object-fit: contain;
  width: 187px;
  height: 66px;
}
#header #headerwrapper #group_logo {
  position: absolute;
  right: 19px;
  top: 16px;
}
</style>
